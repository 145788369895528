import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { apiSecure } from "../../utils/util";

interface stationIds {
  connectorId: string;
  clientId: string;
  idTag: string;
}

interface startCharging {
  connectorId: string;
  clientId: string;
  idTag: string;
  duration: number;
}

interface ConnectorInfo {
  chargeBoxIdentity: string;
  errorCode: string; 
  id: number; 
  status: string;
}

interface ChargeStationResponse {
  billMethod: string; 
  chargeBoxSerialNumber: string;
  chargerDisplayName: string;
  locationDisplayName: string;
  chargeBoxIdentity: string;
  stationStatus_L1: string; 
  stationId: string;
  stationState: string;
  connectors: Array<ConnectorInfo>;
  stationTariff: number;
  stationTariffPerMin: number;
  power: number; 
  powerUnit: string; 

}

export const getStationStatus = createAsyncThunk(
  "charge/stationInfo",
  async (chargeBoxIdentity: string) => {
    try {
      const response = apiSecure.get(`/charging_stations/${chargeBoxIdentity}`);
      return await response;
    } catch (error) {
      return {
        data: {
          desc: "Station not found",
        },
      };
    }
  }
);

export const startCharging = createAsyncThunk(
  "charge/start",
  async (stationId: startCharging) => {
    const { connectorId, clientId, idTag, duration } = stationId;
    try {
      const response = apiSecure.get(
        //`${API_URL}/charging_stations/${id}/connector/${cid}/idTag/${idTag}/clientId/SIM66456789%252FCS-SIEMENS/start`
        `/charging_stations/1/connector/${connectorId}/idTag/${idTag}/clientId/${clientId}/stopTimeInSec/${duration}/start`
      );
      return await response;
    } catch (error: any) {
      let statusCode = error.response.status;
      if (statusCode === 423) {
        return {
          data: {
            status: "Charger Unavailable",
          },
        };
      }
      return error;
    }
  }
);

export const stopCharging = createAsyncThunk(
  "charge/stop",
  async (stationId: stationIds) => {
    const { connectorId, clientId, idTag } = stationId;
    try {
      const response = apiSecure.get(
        //`${API_URL}/charging_stations/${id}/connector/${cid}/idTag/${idTag}/clientId/SIM66456789%252FCS-SIEMENS/stop`
        `/charging_stations/1/connector/${connectorId}/idTag/${idTag}/clientId/${clientId}/stop`
      );
      return await response;
    } catch (error: any) {
      let statusCode = error.response.status;
      if (statusCode === 423) {
        return {
          data: {
            status: "Unable to stop charger",
          },
        };
      }
      return error;
    }
  }
);

export const meterReading = createAsyncThunk(
  "charge/meter",
  async (stationId: stationIds) => {
    const { connectorId, clientId, idTag } = stationId;
    try {
      const response = apiSecure.get(
        //`${API_URL}/charging_stations/${id}/connector/${cid}/idTag/${idTag}/meter`
        `/charging_stations/1/connector/${connectorId}/idTag/${idTag}/meter`
      );
      return await response;
    } catch (error) {
      return error;
    }
  }
);

export const chargeInitialState = () => ({
  loading: false,
  hasErrors: false,
  status: "",
  chargerClientId: "",
  chargeBoxSerialNumber: "",
  stationStatus: "",
  stationStatus_L1: "",
  connectors: [],
  connectorStatus: "",
  chargerDisplayName:"",
  locationDisplayName: "",
  lastSession: {
    transactionId: 0,
    meterStopValue: -1,
    meterStartTime: "",
    meterStopTime: "",
    chargeDuration: 0,
    tariffPerSecond: 0,
    tariffPerMin: 0,
    tariffPerKW: 0,
    billingMethod: "",
    transactionValue: 0,
    otherFees: 0,
    chargingFees: 0,
  },
  stationTariff: 0,
  stationTariffPerMin: 0,
  error: {},
});

export const chargeSlice = createSlice({
  name: "charger",
  initialState: chargeInitialState,
  reducers: {
    getStationClientId: (state, action) => {
      let clientId = action.payload;
      state.chargerClientId = clientId.substring(1).replace("/", "%2F");
    },
    clearChargerState: state => chargeInitialState(),
  },
  extraReducers: {
    [getStationStatus.pending.type]: (state: any, action) => {
      state.loading = true;
    },
    [getStationStatus.fulfilled.type]: (state, action) => {
      let getChargeStationInfoResponse = action.payload;

      let infoResponse = getChargeStationInfoResponse.data.desc;

      if (infoResponse === "Station found") {
        let stationData = getChargeStationInfoResponse.data.station;

        let initialChargeState:ChargeStationResponse = {
          billMethod : stationData.stopTimeInSec,  
          chargeBoxSerialNumber : stationData.chargeBoxSerialNumber,
          chargerDisplayName: stationData.chargerDisplayName,
          locationDisplayName: stationData.locationDisplayName,
          chargeBoxIdentity: stationData.chargeBoxIdentity, 
          stationStatus_L1: stationData.isUserCharging, 
          stationId : stationData.endpoint, 
          stationState: stationData.status, 
          connectors: stationData.connectors, 
          stationTariff: stationData.tariff,
          stationTariffPerMin : stationData.tariffPerMinute,
          power: stationData.power, 
          powerUnit: stationData.powerUnit


        }; 

        let { 
          billMethod, 
          chargeBoxSerialNumber,
          chargerDisplayName,
          locationDisplayName, 
          chargeBoxIdentity, 
          stationStatus_L1, 
          stationId, 
          stationState, 
          connectors, 
          stationTariff,
          stationTariffPerMin,
          power, 
          powerUnit 
        
        } = initialChargeState

        let connectorInfo = stationData.connectors;
        let stationIdRaw = '';
        if (stationId.endsWith('/')) {
          stationIdRaw = stationId.substring(0, stationId.length - 1);
        }
        if (stationId.startsWith('/')) {
          stationIdRaw = stationId.substring(1, stationId.length);
        }
        // let stationIdRaw = stationId.substring(1)
        let encodedChargerClientId = encodeURIComponent(stationIdRaw)

        state.connectors = connectorInfo;
        state.connectorStatus = connectorInfo.map((point:any) => point.status)
        state.stationStatus_L1 = stationStatus_L1;
        state.chargerClientId = encodedChargerClientId;
        state.chargeBoxSerialNumber = chargeBoxSerialNumber;
        state.chargerDisplayName = chargerDisplayName;
        state.locationDisplayName = locationDisplayName;
        state.stationStatus = stationState;
        state.stationTariff = stationTariff;
        state.stationTariffPerMin = stationTariffPerMin;
      }
    },
    [getStationStatus.rejected.type]: (state, action) => {
      state.error = action.payload;
    },
    [startCharging.pending.type]: (state: any, action) => {
      state.loading = true;
    },
    [startCharging.fulfilled.type]: (state, action) => {
      let getChargeStationStartResponse = action.payload;
      let startResponse = getChargeStationStartResponse.data.status;
      if (startResponse === "ok") {
        state.status = "engaged";
      }

      if (startResponse === "Charger Unavailable") {
        state.status = "Unavailable";
      }
      state.lastSession.meterStopValue = -1;
      state.loading = false;
    },
    [startCharging.rejected.type]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [stopCharging.pending.type]: (state: any, action) => {
      state.loading = true;
    },
    [stopCharging.fulfilled.type]: (state, action) => {
      let getChargeStationStopResponse = action.payload;
      let stopResponse = getChargeStationStopResponse.data.status;

      if (stopResponse === "Unable to stop charger") {
        state.status = "disengaged";
      }
      if (stopResponse === "ok") {
        state.status = "disengaged";
      }
      state.loading = false;
    },
    [stopCharging.rejected.type]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [meterReading.pending.type]: (state: any, action) => {
      state.lastSession.meterStopValue = -1;
      state.loading = true;
    },
    [meterReading.fulfilled.type]: (state, action) => {
      let getMeterValueResponse = action.payload;
      let meterData = getMeterValueResponse.data;
      let meterValue = meterData.meterStop
        ? Number(meterData.meterStop / 1000)
        : -1;
      let duration = meterData.meterTimeInMilliSecs
        ? meterData.meterTimeInMilliSecs
        : 0;
      state.lastSession.transactionId = meterData.transactionId;
      state.lastSession.meterStopValue = meterValue;
      state.lastSession.meterStartTime = meterData.meterStartTime;
      state.lastSession.meterStopTime = meterData.meterStopTime;
      state.lastSession.chargeDuration = duration;
      state.lastSession.tariffPerSecond = meterData.tariffPerSecond;
      state.lastSession.tariffPerMin = meterData.tariffPerMin;
      state.lastSession.tariffPerKW = meterData.tariffPerKW;
      state.lastSession.billingMethod = meterData.billCalculationMethod;
      state.lastSession.transactionValue = meterData.transactionAmount;
      state.lastSession.otherFees = meterData.otherFees;
      state.lastSession.chargingFees = meterData.chargingFees;
      state.loading = false;
    },
    [meterReading.rejected.type]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const { getStationClientId, clearChargerState } = chargeSlice.actions;

export const chargerId = (state: any) => state.charger.chargerClientId;
export const chargeBoxId = (state: any) => state.charger.chargeBoxSerialNumber;
export const chargerDisplayName = (state:any) => state.charger.chargerDisplayName;
export const locationDisplayName = (state:any) => state.charger.locationDisplayName;
export const connectorStatus = (state: any) => state.charger.connectorStatus;
export const stationChargingStatus = (state: any) => state.charger.stationStatus_L1;
export const stationTariff = (state: any) => state.charger.stationTariff;
export const stationTariffPerMin = (state: any) => state.charger.stationTariffPerMin;
export const billSummary = (state: any) => state.charger.lastSession;

export default chargeSlice.reducer;
